import useMicrositeContext from '@/providers/MicrositeContextProvider';
import { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import SpinnerBox from '../SpinnerBox';
import { MicrositePopupProps } from './interface';
import styles from './styles.module.scss';
import { useMicrositePopup } from './useMicrositePopup';

export default function Popup({
  page,
  popups,
}: MicrositePopupProps): JSX.Element {
  const [modal, setModal] = useState<boolean>(false);

  const { accountSlug, promotionSlug, formLoaded } = useMicrositeContext();

  const { runPopup, loading, data } = useMicrositePopup();

  const toggle = () => setModal(!modal);

  const openPopup = (event: Event) => {
    event.preventDefault();

    const target = event.currentTarget as HTMLAnchorElement;
    const href = target.getAttribute('href');
    let slug = target.getAttribute('data-popup-id');

    if (!slug && href) {
      slug = href.indexOf('#') >= 0 ? href.slice(1) : href;
    }

    if (slug) {
      toggle();
      runPopup?.(accountSlug, promotionSlug, slug);
    }
  };

  useEffect(() => {
    const popups = document.getElementsByClassName('popup-loader');

    const popupArray = Array.from(popups);

    for (let i = 0; i < popupArray.length; i++) {
      popupArray[i].addEventListener('click', openPopup);
    }

    return () => {
      for (let i = 0; i < popupArray.length; i++) {
        popupArray[i].removeEventListener('click', openPopup);
      }
    };
  }, [page, formLoaded]);

  useEffect(() => {
    if (popups) {
      popups.map((popup) => [
        toggle(),
        runPopup?.(accountSlug, promotionSlug, popup),
      ]);
    }
  }, [popups]);

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className={styles.modalDialog}
      wrapClassName="blkd-alt"
    >
      <ModalHeader toggle={toggle} className={styles.modalHeader}>
        {data?.show_title && data?.title}
      </ModalHeader>
      {loading ? (
        <SpinnerBox />
      ) : (
        <ModalBody
          dangerouslySetInnerHTML={{
            __html: data?.content ?? '',
          }}
          className={`blkd-alt ${styles.modalBody}`}
        />
      )}
    </Modal>
  );
}

import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { MicrositePopup } from './interface';

const POPUP = gql`
  query micrositePopup(
    $accountSlug: String!
    $promotionSlug: String!
    $slug: String!
  ) {
    micrositePopup(
      accountSlug: $accountSlug
      promotionSlug: $promotionSlug
      slug: $slug
    ) {
      show_title
      title
      content
      show_when_microsite_is_loaded
      status
    }
  }
`;

export function useMicrositePopup(): {
  loading: boolean;
  data?: MicrositePopup;
  runPopup?: (accountSlug: string, promotionSlug: string, slug: string) => void;
} {
  const [run, { data, loading }] = useLazyQuery<{
    micrositePopup: MicrositePopup;
  }>(POPUP, {
    onError: (error: ApolloError) => {
      console.log('POPUP', error);
    },
  });

  const runPopup = (
    accountSlug: string,
    promotionSlug: string,
    slug: string
  ) => {
    run({
      variables: {
        accountSlug,
        promotionSlug,
        slug,
      },
    });
  };

  if (loading) {
    return {
      loading: true,
    };
  }

  return {
    loading: false,
    data: data?.micrositePopup,
    runPopup,
  };
}
